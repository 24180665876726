import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MEPlus from '../MEPlus/MEPlus';
import styles from './hero.module.scss'

export default function Hero({ card, tags, title }) {

    return (
        <div className={styles.container}>

            <div className={styles.outer_img_container}>
                <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />

                <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
            </div>


            <div className={styles.constraints}>
                <div className={styles.meplus}>
                    <MEPlus />
                </div>

                <div className={styles.text}>
                    <p>
                        {tags && tags.map((tag) => (
                            <span key={tag.id} className="small-text tag">{tag.tag}</span>
                        ))}
                    </p>
                    {title && <h1>{title}</h1>}

                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                </div>

                <div className={styles.inner_img_container}>
                    <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                </div>
            </div>

        </div>
    )
}