import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './article-columns.module.scss';

export default function ArticleColumns({ cards, poem }) {
    const [tallCard, setTallCard] = useState();
    const [firstSmallCard, setFirstSmallCard] = useState();
    const [smallCards, setSmallCards] = useState();

    useEffect(() => {
        if (cards) {
            let clonedCards = cloneDeep(cards)
            let tallCard = clonedCards.shift();
            let firstSmallCard = clonedCards.shift();

            setTallCard(tallCard);
            setFirstSmallCard(firstSmallCard);
            setSmallCards(clonedCards);
        }
    }, [cards])


    return (
        <div className={styles.section_container}>

            {tallCard && <Link to={`/artikel/${tallCard.attributes.slug}`}>
                <div className={styles.tall_card}>

                    <div className={styles.img_container}>
                        <img src={formatStrapiImage(tallCard.attributes.hero.image)} alt={tallCard.attributes.hero.image.data.attributes.alternativeText} />
                    </div>


                    <div className={styles.text_container}>
                        <p>
                            {tallCard.attributes.tags.map((tag) => (
                                tag !== 'artikel' && <span key={tag.id} className="small-text tag">{tag.tag}</span>
                            ))}
                        </p>
                        <h2>{tallCard.attributes.title}</h2>
                        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={tallCard.attributes.hero.text} />
                    </div>

                </div>
            </Link>}


            <div className={styles.small_cards}>
                {firstSmallCard && <Link to={`/artikel/${firstSmallCard.attributes.slug}`}>
                    <div className={styles.card}>

                        <div className={styles.img_container}>
                            <img src={formatStrapiImage(firstSmallCard.attributes.hero.image)} alt={firstSmallCard.attributes.hero.image.data.attributes.alternativeText} />
                        </div>

                        <div className={styles.text_container}>
                            <p>
                                {firstSmallCard.attributes.tags.map((tag) => (
                                    tag !== 'artikel' && <span key={tag.id} className="small-text tag">{tag.tag}</span>
                                ))}
                            </p>
                            <h2>{firstSmallCard.attributes.title}</h2>
                            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={firstSmallCard.attributes.hero.text} />
                        </div>

                    </div>
                </Link>}

                {poem && <div className={`${styles.card} ${styles.poem}`}>
                    <p className={`${styles.poem_tag} small-text tag`}>Dikt</p>
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={poem.text} />
                    <p><b>{poem.writer}</b></p>
                </div>}

                {smallCards && smallCards.map((card) =>
                    <Link to={`/artikel/${card.attributes.slug}`} key={card.id}>
                        <div className={styles.card}>

                            <div className={styles.img_container}>
                                <img src={formatStrapiImage(card.attributes.hero.image)} alt={card.attributes.hero.image.data.attributes.alternativeText} />
                            </div>

                            <div className={styles.text_container}>
                                <p>
                                    {card.attributes.tags.map((tag) => (
                                        tag !== 'artikel' && <span key={tag.id} className="small-text tag">{tag.tag}</span>
                                    ))}
                                </p>
                                <h2>{card.attributes.title}</h2>
                                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.attributes.hero.text} />
                            </div>

                        </div>
                    </Link>
                )}
            </div>
        </div>
    )
}