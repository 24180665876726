import styles from './wide-header.module.scss';

export default function WideHeader({title}) {

    return (
        <div className={styles.section_container}>
            <span></span>
            <h1>{title ? title : 'Rubrik'}</h1>
            <span></span>
        </div>
    )
}