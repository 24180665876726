import styles from './sponsored.module.scss'

export default function Sponsored() {

    return (
        <div className={styles.container}>
            <p>Modern Elder Sverige sponsor</p>
            {/* eslint-disable-next-line */}
            <a href="https://solhemmet.com/" target="_blank" rel="noopener">
                <img src='/images/Solhemmet_new_logo_RGB_Solhemmet_beige_sun_neg_text.svg' alt='Solhemmet logo' />
            </a>
        </div>
    )
}
