import { useState, useEffect } from "react";
import { useGetEntryById, useGetEntriesByContentType } from '../../utils/js/getStrapiContent';
import { cloneDeep } from 'lodash';
import Loading from "../../components/Loading/Loading";
import Error404 from "../../components/Error404/Error404";
import HeadTags from "../../components/HeadTags/HeadTags";
import Sponsored from "../../components/Sponsored/Sponsored";
import ReadMoreHero from "../../components/ReadMoreHero/ReadMoreHero";
import WideHeader from "../../components/WideHeader/WideHeader";
import ArticleColumns from "../../components/ArticleColumns/ArticleColumns";
import SlimCards from "../../components/SlimCards/SlimCards";
import styles from './frontpage.module.scss';

export default function FrontPage() {
    const { data: frontpageData, isPending: frontpageIsPending, error: frontpageError } = useGetEntryById('modern-elder-frontpage');
    const { data: articlesData, isPending: articlesIsPending, error: articlesError } = useGetEntriesByContentType('modern-elder-articles');
    const [firstArticles, setFirstArticles] = useState([]);
    const [restOfArticles, setRestOfArticles] = useState([]);


    useEffect(() => {
        if (articlesData) {
            let clonedArticles = cloneDeep(articlesData.data);
            let profileArticles = [];

            clonedArticles.reverse().forEach((article) => {
                article.attributes.tags.forEach((tag) => {
                    if (tag.tag.toLowerCase() === 'profil') profileArticles.push(article);
                })
            })

            let spliced = profileArticles.splice(0, 3);

            setFirstArticles(spliced);
            setRestOfArticles(profileArticles);
        }
    }, [articlesData])


    return (
        <div className={styles.container}>
            {(frontpageIsPending && articlesIsPending) && <Loading />}
            {(frontpageError && articlesError) && <Error404 />}
            {(frontpageData && articlesData) && (
                <>
                    {frontpageData.data.attributes.seo && <HeadTags seo={frontpageData.data.attributes.seo} />}
                    <Sponsored />
                    {frontpageData.data.attributes.hero && <ReadMoreHero cards={[frontpageData.data.attributes.hero]} />}
                    <WideHeader title={'Artiklar'} />
                    {firstArticles.length >= 1 && <ArticleColumns cards={firstArticles} poem={frontpageData.data.attributes.poem} />}
                    <div className='red-border'></div>
                    {restOfArticles.length >= 1 && <SlimCards cards={restOfArticles} />}
                </>
            )}
        </div>
    )
}