import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import WideCards from '../WideCards/WideCards';
import SlimCards from '../SlimCards/SlimCards';
import styles from './cards-grid.module.scss';

export default function CardsGrid({ cards }) {
    const [wideCard, setWideCard] = useState();
    const [slimCards, setSlimCards] = useState();


    useEffect(() => {
        if (cards) {
            let clonedCards = cloneDeep(cards)
            let firstCard = clonedCards.shift();
            setWideCard([firstCard]);
            setSlimCards(clonedCards);
        }
    }, [cards])


    return (
        <div className={styles.container}>
            {wideCard && <WideCards cards={wideCard} />}
            {slimCards && <SlimCards cards={slimCards} />}
        </div>
    )
}