import { Link } from 'react-router-dom';
import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './wide-cards.module.scss';

export default function WideCards({ cards }) {

    return (
        <div className={styles.section_container}>

            {cards.map((card) =>

                <Link to={`/artikel/${card.attributes.slug}`} key={card.id}>
                    <div className={styles.card_container}>

                        <div className={styles.text_container}>
                            <p>
                                {card.attributes.tags.map((tag) => (
                                    <span key={tag.id} className="small-text tag">{tag.tag}</span>
                                ))}
                            </p>
                            <h2>{card.attributes.title}</h2>
                            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.attributes.hero.text} />
                        </div>

                        <div className={styles.img_container}>
                            <img src={formatStrapiImage(card.attributes.hero.image)} alt={card.attributes.hero.image.data.attributes.alternativeText} />
                        </div>

                    </div>
                </Link>
            )}

        </div>
    )
}