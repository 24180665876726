import { useGetEntryById } from '../../utils/js/getStrapiContent';
import Loading from "../../components/Loading/Loading";
import Error404 from "../../components/Error404/Error404";
import HeadTags from '../../components/HeadTags/HeadTags';
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import WideHeader from "../../components/WideHeader/WideHeader";
import TextImagesColumns from "../../components/TextImagesColumns/TextImagesColumns";
import SimpleTextCard from "../../components/SimpleTextCard/SimpleTextCard";
import CircleCards from "../../components/CircleCards/CircleCards";
import AboutUsCard from "../../components/AboutUsCard/AboutUsCard";
import Sponsored from "../../components/Sponsored/Sponsored";
import styles from './about-us.module.scss';

export default function AboutUs() {
    const { data, isPending, error } = useGetEntryById('modern-elder-about-us-page');

    return (
        <div className={styles.container}>
            {isPending && <Loading />}
            {error && <Error404 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.hero && <ImageBanner hero={data.data.attributes.hero} />}
                    {data.data.attributes.infoTextHeader && <WideHeader title={data.data.attributes.infoTextHeader} />}
                    {data.data.attributes.infoText && <TextImagesColumns infoText={data.data.attributes.infoText} />}
                    {data.data.attributes.shortText && <SimpleTextCard text={data.data.attributes.shortText} />}
                    {data.data.attributes.circleCards && <CircleCards cards={data.data.attributes.circleCards} />}
                    {data.data.attributes.aboutUsHeader && <WideHeader title={data.data.attributes.aboutUsHeader} />}
                    {data.data.attributes.aboutUs && <AboutUsCard cards={data.data.attributes.aboutUs} />}
                    <Sponsored />
                </>
            )}
        </div>
    )
}