import { useState, useEffect } from 'react';
import { useGetEntriesByContentType } from '../../utils/js/getStrapiContent';
import { cloneDeep } from 'lodash';
import Loading from '../../components/Loading/Loading';
import Error404 from '../../components/Error404/Error404';
import HeadTagsDefault from '../../components/HeadTagsDefault/HeadTagsDefault';
import WideHeader from '../../components/WideHeader/WideHeader';
import SlimCards from '../../components/SlimCards/SlimCards';
import Sponsored from '../../components/Sponsored/Sponsored';
import styles from './films.module.scss';

export default function Films() {
    const { data, isPending, error } = useGetEntriesByContentType('modern-elder-articles');
    const [films, setFilms] = useState();


    useEffect(() => {
        if (data) {
            let clonedArticles = cloneDeep(data.data);
            let tempFilms = [];

            clonedArticles.reverse().forEach((article) => {
                article.attributes.tags.forEach((tag) => {
                    if (tag.tag.toLowerCase() === 'film') tempFilms.push(article);
                })
            })

            setFilms(tempFilms)
        }
    }, [data])


    return (
        <>
            <HeadTagsDefault title={'Filmer - Modern Elder'} />

            <div className={styles.container}>
                {isPending && <Loading />}
                {error && <Error404 />}
                {data && (
                    <>
                        {films && <>
                            <WideHeader title={'Filmer'} />

                            <div className={styles.articles}>
                                <SlimCards cards={films} />
                            </div>

                            <Sponsored />
                        </>}
                    </>
                )}
            </div>
        </>
    )
}