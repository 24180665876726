import { Link } from 'react-router-dom';
import styles from './content-blocked-banner.module.scss';

export default function ContentBlockedBanner({ url }) {
    return (
        <div className={styles.container}>
            <h5>Här skulle en video ha visats</h5>
            <p>Tredjeparts-cookies har inte godkänts. Därför kan den här videon endast ses på <a href={`https://www.youtube.com/watch?v=${url}`} rel="noreferrer" target='_blank'>webbplatsen som den kommer ifrån.</a></p>
            <Link to="/om-hemsidan" className="navlink">Ändra cookie-inställningar <span className="link-arrow">➞</span></Link>
        </div>
    )
} 