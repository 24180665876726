import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetEntryByContentTypeAndSlug } from '../../utils/js/getStrapiContent';
import Loading from '../../components/Loading/Loading';
import Error404 from '../../components/Error404/Error404';
import HeadTags from '../../components/HeadTags/HeadTags';
import Sponsored from '../../components/Sponsored/Sponsored';
import Hero from '../../components/Hero/Hero';
import YoutubeEmbedSection from '../../components/YoutubeEmbedSection/YoutubeEmbedSection';
import TextSection from '../../components/TextSection/TextSection';
import Byline from '../../components/Byline/Byline';
import WatchMoreSection from '../../components/WatchMoreSection/WatchMoreSection';
import ReadMoreSection from '../../components/ReadMoreSection/ReadMoreSection';
import styles from './article-template.module.scss';

export default function ArticleTemplate() {
    const { identifier } = useParams();
    const { data, isPending, error } = useGetEntryByContentTypeAndSlug('modern-elder-articles', identifier)
    const [isFilm, setIsFilm] = useState(false);


    useEffect(() => {
        if (data) {
            data.data.attributes.tags.forEach(tag => {
                if (tag.tag.toLowerCase() === 'film') setIsFilm(true);
            });
        }
    }, [data])


    return (
        <div className={styles.container}>
            {isPending && <Loading />}
            {error && <Error404 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    <Sponsored />
                    {(data.data.attributes.hero && data.data.attributes.tags) &&
                        <Hero
                            card={data.data.attributes.hero}
                            tags={data.data.attributes.tags}
                            title={data.data.attributes.title}
                        />
                    }
                    {data.data.attributes.youtubeVideoId && <YoutubeEmbedSection cards={[data.data.attributes.youtubeVideoId]} />}
                    {data.data.attributes.text && <TextSection text={data.data.attributes.text} />}
                    {(data.data.attributes.byLineImage && data.data.attributes.byLineText) &&
                        <Byline
                            image={data.data.attributes.byLineImage}
                            text={data.data.attributes.byLineText}
                        />
                    }
                    <div className='red-border'></div>
                    {isFilm ? <WatchMoreSection currentArticle={identifier} /> : <ReadMoreSection currentArticle={identifier} />}
                </>
            )}
        </div>
    )
}