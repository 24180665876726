import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './circle-cards.module.scss'

export default function CircleCards({ cards }) {

    return (
        <div className={styles.container}>
            {cards.map((card) =>

                <div className={styles.card} key={card.id}>

                    <div className={styles.img_container}>
                        <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                    </div>

                    <div className={styles.text_container}>
                        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                    </div>

                </div>

            )}
        </div>
    )
}