import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './text-section.module.scss';

export default function TextSection({ text }) {

    return (
        <div className={styles.section_container} >
            <div className={styles.text_container}>
                <div className={styles.text}>
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={text} />
                </div>
            </div>
        </div>
    )
}