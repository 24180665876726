import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import MEPlus from '../MEPlus/MEPlus';
import styles from './header.module.scss';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }


    return (
        <header>
            <div className={styles.constraints}>

                <div className={styles.menu_btn_container}>
                    <button className={`${styles.menu_btn} ${isOpen ? styles.menu_btn_active : ''}`} onClick={toggleIsOpen} aria-label='Toggle navigation menu'>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>


                <div className={`${styles.nav} ${isOpen ? styles.nav_open : ''}`}>
                    <Navigation toggleIsOpen={toggleIsOpen} />
                </div>

                <nav className={styles.nav_outer}>
                    <NavigationOuter />
                </nav>

                <Link to='/' className={styles.home} onClick={() => setIsOpen(false)}>
                    <img src='/images/modern-elder-sverige-logo.svg' alt='Modern Elder Sverige logo' />

                    <div className={styles.meplus}>
                        <MEPlus />
                    </div>
                </Link>

            </div>
        </header>
    )
}


function NavigationOuter({ toggleIsOpen }) {
    return (
        <ul>
            <li>
                <NavLink to="/artiklar" className="navlink" onClick={toggleIsOpen}>Profiler</NavLink>
            </li>
            <li>
                <NavLink to="/filmer" className="navlink" onClick={toggleIsOpen}>Filmer</NavLink>
            </li>
        </ul>
    )
}


function Navigation({ toggleIsOpen }) {
    return (
        <nav>
            <div className={styles.outer_in}>
                <NavigationOuter toggleIsOpen={toggleIsOpen} />
            </div>

            <ul>
                <li>
                    <NavLink to="/om-oss" className="navlink" onClick={toggleIsOpen}>Om oss</NavLink>
                </li>
                <li>
                    <NavLink to="/vart-syfte" className="navlink" onClick={toggleIsOpen}>Vårt syfte</NavLink>
                </li>
            </ul>
        </nav>
    )
}