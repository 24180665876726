import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './image-banner.module.scss'

export default function ImageBanner({ hero }) {

    return (
        <div className={styles.container}>
            <div className={styles.card} >

                <div className={styles.img_container}>
                    <img src={formatStrapiImage(hero.image)} alt={hero.image.data.attributes.alternativeText} />
                </div>

                <div className={`${styles.text_container} large-text`}>
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={hero.text} />
                </div>

            </div>
        </div>
    )
}