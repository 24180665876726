import { useState, useEffect } from "react";
import { cloneDeep } from 'lodash';
import { useGetEntriesByContentType } from '../../utils/js/getStrapiContent';
import SlimCards from "../../components/SlimCards/SlimCards";
import styles from './read-more-section.module.scss';

export default function ReadMoreSection({ currentArticle }) {
    const { data } = useGetEntriesByContentType('modern-elder-articles');
    const [articles, setArticles] = useState();


    useEffect(() => {
        if (data && currentArticle) {
            let clonedArticles = cloneDeep(data.data);
            let tempArticles = [];

            // only take the articles with Profil tag
            clonedArticles.reverse().forEach((article) => {
                article.attributes.tags.forEach((tag) => {
                    if (tag.tag.toLowerCase() === 'profil') tempArticles.push(article);
                })
            })

            // get id of the current article
            let currentIndex;
            tempArticles.forEach(article => {
                if (article.attributes.slug === currentArticle) currentIndex = article.id;
            })

            // select articles published earlier than the current article
            let earlierArticles = []
            tempArticles.forEach(article => {
                if (article.id < currentIndex) earlierArticles.push(article)
            })

            // if earlierArticles is less than 3, fill in from the start of tempArticles
            if (earlierArticles.length < 3) {
                let i = 0;
                while (earlierArticles.length < 3) {
                    earlierArticles.push(tempArticles[i])
                    i++;
                }
            }

            // only show first three
            let firstThree = earlierArticles.slice(0, 3);
            setArticles(firstThree)
        }
    }, [data, currentArticle])


    return (
        <>
            {articles &&
                <div className={styles.container}>
                    <h3>Läs mer</h3>
                    <SlimCards cards={articles} />
                </div>
            }
        </>
    )
}