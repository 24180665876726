import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './byline.module.scss';

export default function Byline({ image, text }) {

    return (
        <div className={styles.section_container}>
            <div className={styles.container}>

                <div className={styles.img}>
                    <img src={formatStrapiImage(image)} alt={image.data.attributes.alternativeText} />
                </div>

                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={text} />
            </div>
        </div>
    )
}