import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './about-us-card.module.scss';

export default function AboutUsCard({ cards }) {

    return (
        <div className={styles.section_container}>

            {cards.map((card) =>

                <div className={styles.card} key={card.id}>

                    <div className={styles.top}>
                        <div className={styles.img_container}>
                            <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                        </div>

                        <h3>{card.title}</h3>
                    </div>

                    <div className={styles.text_container}>
                        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                    </div>

                </div>
            )}

        </div>
    )
}