import { Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CookieConsent from './components/CookieConsent/CookieConsent';
import Error404 from './components/Error404/Error404';
import FrontPage from './pages/FrontPage/FrontPage';
import Articles from './pages/Articles/Articles';
import Films from './pages/Films/Films';
import ArticleTemplate from './pages/ArticleTemplate/ArticleTemplate';
import CookiePreferences from './pages/CookiePreferences/CookiePreferences';
import OurPurpose from './pages/OurPurpose/OurPurpose';
import AboutUs from './pages/AboutUs/AboutUs';
import './App.scss';

function App() {
  return (
    <div className="App">

      <Header />

      <div className="content">
        <Routes>

          <Route path='/' element={<FrontPage />} />

          <Route path='/artiklar' element={<Articles />} />

          <Route path='/filmer' element={<Films />} />

          <Route path='/artikel/:identifier' element={<ArticleTemplate />} />

          <Route path='/om-hemsidan' element={<CookiePreferences />} />

          <Route path='/vart-syfte' element={<OurPurpose />} />

          <Route path='/om-oss' element={<AboutUs />} />

          <Route path='*' element={<Error404 />} />

        </Routes>
      </div>

      <CookieConsent />
      <Footer />

    </div>
  );
}

export default App;
