// check if there are formats. if there are, check if there is a large format
export default function formatStrapiImage(image) {
    let img = '';

    if (image.data.attributes.formats != null) {
        if (image.data.attributes.formats.large != null) {
            img = image.data.attributes.formats.large.url;
        } else {
            img = image.data.attributes.url
        }
    } else {
        img = image.data.attributes.url
    }

    return img;
}