import { useState, useEffect } from "react";
import { cloneDeep } from 'lodash';
import { useGetEntriesByContentType } from '../../utils/js/getStrapiContent';
import SlimCards from "../SlimCards/SlimCards";
import styles from './watch-more-section.module.scss';

export default function WatchMoreSection({ currentArticle }) {
    const { data } = useGetEntriesByContentType('modern-elder-articles');
    const [articles, setArticles] = useState();


    // make this sorting similiar to ReadMoreSection when there's more films to sort through
    useEffect(() => {
        if (data && currentArticle) {
            let clonedArticles = cloneDeep(data.data);
            let tempArticles = [];

            // only take the articles with Film tag
            clonedArticles.reverse().forEach((article) => {
                article.attributes.tags.forEach((tag) => {
                    if (tag.tag.toLowerCase() === 'film') tempArticles.push(article);
                })
            })

            // remove current article
            let removedCurrent = [];
            tempArticles.forEach(article => {
                if (article.attributes.slug !== currentArticle) removedCurrent.push(article)
            })

            // only show first three
            let firstThree = removedCurrent.slice(0, 3);
            setArticles(firstThree)
        }
    }, [data, currentArticle])


    return (
        <>
            {articles &&
                <div className={styles.container}>
                    <h3>Se mer</h3>
                    <SlimCards cards={articles} />
                </div>
            }
        </>
    )
}