import { useState, useEffect } from 'react';
import ContentBlockedBanner from '../ContentBlockedBanner/ContentBlockedBanner';
import styles from './youtube-embed-section.module.scss';

export default function YoutubeEmbedSection({ cards }) {
    const [showVideo, setShowVideo] = useState();

    useEffect(() => {
        const consent = localStorage.getItem('third-party-cookie-consent')
        if (consent) {
            setShowVideo(JSON.parse(consent))
        } else {
            setShowVideo(false)
        }
    }, [])

    return (
        <div className={styles.section_container}>

            {cards.map((card) => (
                showVideo ?
                    <div className={styles.video_container} key={card}>
                        <iframe
                            className={styles.video}
                            title="Video"
                            src={`https://www.youtube-nocookie.com/embed/${card}?modestbranding=1&color=white&rel=0`}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div>
                    :
                    <ContentBlockedBanner url={card} key={card} />
            ))}

        </div>
    )
}