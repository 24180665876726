import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Link } from 'react-router-dom'
import styles from './read-more-hero.module.scss';

export default function ReadMoreHero({ cards }) {

    return (
        <div className={styles.section_container}>

            {cards.map((card) =>

                <div className={styles.card} key={card.id}>

                    <div className={styles.img_container}>
                        <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                    </div>

                    <div className={styles.text_container}>
                        <div className={styles.text}>
                            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                        </div>

                        <Link to={`${card.link}`} className={`${styles.button} btn`}>Läs mer <span className="link-arrow">➞</span></Link>
                    </div>

                </div>
            )}

        </div>
    )
}