import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './text-images-columns.module.scss'

export default function TextImagesColumns({ infoText }) {

    return (
        <div className={styles.container}>

            <div className={styles.text_container}>
                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={infoText.text} />
            </div>


            <div className={styles.img_column}>

                {infoText.images.map((card) =>
                    <div className={styles.img_container} key={card.id}>
                        <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                    </div>
                )}

            </div>
        </div>
    )
}