import { useGetEntryById } from '../../utils/js/getStrapiContent';
import Loading from "../../components/Loading/Loading";
import Error404 from "../../components/Error404/Error404";
import HeadTags from '../../components/HeadTags/HeadTags';
import HeartBanner from "../../components/HeartBanner/HeartBanner";
import WideHeader from "../../components/WideHeader/WideHeader";
import LargeImage from "../../components/LargeImage/LargeImage";
import TextSection from "../../components/TextSection/TextSection";
import SimpleTextCard from "../../components/SimpleTextCard/SimpleTextCard";
import styles from './our-purpose.module.scss';

export default function OurPurpose() {
    const { data, isPending, error } = useGetEntryById('modern-elder-our-purpose-page');

    return (
        <div className={styles.container}>
            {isPending && <Loading />}
            {error && <Error404 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.heartBanner && <HeartBanner text={data.data.attributes.heartBanner} />}
                    {data.data.attributes.ourPurposeHeader && <WideHeader title={data.data.attributes.ourPurposeHeader} />}
                    {data.data.attributes.imageAndText && <LargeImage cards={[data.data.attributes.imageAndText]} />}
                    {data.data.attributes.text && <TextSection text={data.data.attributes.text} />}
                    {data.data.attributes.shortText && <SimpleTextCard text={data.data.attributes.shortText} />}
                </>
            )}
        </div>
    )
}