import { Link } from 'react-router-dom';
import MEPlus from '../MEPlus/MEPlus';
import styles from './footer.module.scss'

export default function Footer() {

    return (
        <footer className={styles.footer}>
            <div className={styles.constraints}>
                <Link to="/" className={styles.logo}>
                    <img src='/images/modern-elder-sverige-logo.svg' alt='Modern Elder Sverige logo' />

                    <div className={styles.meplus}>
                        <MEPlus />
                    </div>
                </Link>

                <div className={styles.links}>
                    <Link className="small-text" to="/om-hemsidan">Om hemsidan/inställningar</Link>
                    {/* eslint-disable-next-line */}
                    <a className={`${styles.solhemmet} small-text`} href="https://solhemmet.com/fastigheter/seniorboenden" target="_blank" rel="noopener">Solhemmet - Seniorboenden</a>
                </div>
            </div>
        </footer>
    )
}