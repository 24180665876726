import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './simple-text-card.module.scss';

export default function SimpleTextCard({ text }) {

    return (
        <div className={styles.section_container}>

            <div className={`${styles.card} large-text`} >
                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={text} />
            </div>

        </div>
    )
}