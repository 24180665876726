import { Helmet } from "react-helmet-async"

export default function HeadTagsDefault({ title }) {
    return (
        <>
            <Helmet prioritizeSeoTags>
                {title ? <title>{title}</title> : <title>Modern Elder</title>}
                <meta name="description" content="Modern Elder är en plattform där människor berättar om sina drömmar, om sina kall och om hur ålder ger oss tillåtelse att vara de vi är menade att vara." />

                <meta property="og:title" content={title ? title : "Modern Elder"} />
                <meta property="og:description" content="Modern Elder är en plattform där människor berättar om sina drömmar, om sina kall och om hur ålder ger oss tillåtelse att vara de vi är menade att vara." />
                <meta property="og:image" content="/images/og_frontpage.jpg" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:width" content="1200" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title ? title : "Modern Elder"} />
                <meta name="twitter:description" content="Modern Elder är en plattform där människor berättar om sina drömmar, om sina kall och om hur ålder ger oss tillåtelse att vara de vi är menade att vara." />
                <meta name="twitter:image" content="/images/og_frontpage.jpg" />
                <meta name="twitter:image:alt" content="Modern Elder" />
            </Helmet>
        </>
    )
}