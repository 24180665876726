import { useState, useEffect } from 'react';
import { useGetEntriesByContentType } from '../../utils/js/getStrapiContent';
import { cloneDeep } from 'lodash';
import Loading from '../../components/Loading/Loading';
import Error404 from '../../components/Error404/Error404';
import HeadTagsDefault from '../../components/HeadTagsDefault/HeadTagsDefault';
import WideHeader from '../../components/WideHeader/WideHeader';
import CardsGrid from '../../components/CardsGrid/CardsGrid';
import Sponsored from '../../components/Sponsored/Sponsored';
import styles from './articles.module.scss';

export default function Articles() {
    const { data, isPending, error } = useGetEntriesByContentType('modern-elder-articles');
    const [articles, setArticles] = useState();


    useEffect(() => {
        if (data) {
            let clonedArticles = cloneDeep(data.data);
            let tempArticles = [];

            clonedArticles.reverse().forEach((article) => {
                article.attributes.tags.forEach((tag) => {
                    if (tag.tag.toLowerCase() === 'profil') tempArticles.push(article);
                })
            })

            setArticles(tempArticles)
        }
    }, [data])


    return (
        <>
            <HeadTagsDefault title={'Profiler - Modern Elder'} />

            <div className={styles.container}>
                {isPending && <Loading />}
                {error && <Error404 />}
                {data && (
                    <>
                        <WideHeader title={'Profiler'} />

                        {articles && <div className={styles.articles}>
                            <CardsGrid cards={articles} />
                        </div>}

                        <Sponsored />
                    </>
                )}
            </div>
        </>
    )
}