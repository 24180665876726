import styles from './meplus.module.scss'

export default function MEPlus({ color }) {

    return (
        <div className={styles.container1}>
            <div className={styles.container2}>

                <svg className={styles.plus} width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="27" width="74" height="20" rx="10" fill={color ? color : "#ffffff"} />
                    <rect x="47" width="74" height="20" rx="10" transform="rotate(90 47 0)" fill={color ? color : "#ffffff"} />
                </svg>

                <svg className={styles.shadow} width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="27" width="74" height="20" rx="10" fill="black" />
                    <rect x="47" width="74" height="20" rx="10" transform="rotate(90 47 0)" fill="black" />
                </svg>

            </div>
        </div>
    )
}