import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './heart-banner.module.scss'

export default function HeartBanner({ text }) {

    return (
        <div className={styles.container}>

            <div className={styles.hearts_container}>
                <div className={styles.hearts}>
                    <div>
                        <Heart color={"#3D4142"} />
                    </div>
                    <div>
                        <Heart color={"#2B74A1"} />
                    </div>
                </div>
            </div>


            <div className={`${styles.text} large-text`}>
                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={text} />
            </div>

            <div className={styles.background_banner}> </div>
        </div>
    )
}

function Heart({ color }) {
    return (
        <div className={styles.svg_container}>
            <svg version="1.1" viewBox="0 0 544.582 544.582" preserveAspectRatio="xMinYMin meet" className={styles.svg_content} fill={color ? color : "#ffffff"} >
                <g>
                    <path d="M448.069,57.839c-72.675-23.562-150.781,15.759-175.721,87.898C247.41,73.522,169.303,34.277,96.628,57.839
		C23.111,81.784-16.975,160.885,6.894,234.708c22.95,70.38,235.773,258.876,263.006,258.876
		c27.234,0,244.801-188.267,267.751-258.876C561.595,160.732,521.509,81.631,448.069,57.839z"/>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>
        </div>
    )
}
